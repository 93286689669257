<template>
  <div class="d-flex justify-center" style="max-width: 360px">
    <div class="w-full">
      <v-card
        style="background-color: #fff1d6"
        elevation="0"
        class="pa-3 w-full print-preview-card"
      >
        <div class="d-flex flex-column align-center justify-center">
          <div
            v-if="printOptions.printLogo"
            class="image-container d-flex justify-center mb-2"
          >
            <img
              v-if="printOptions.printPlatformLogo"
              style="max-width: 90%; max-height: 200px; filter: grayscale(100%)"
              src="/img/logo/logo-dark.png"
              class="rounded"
            />
            <img
              v-else-if="printOptions.printLogo"
              style="max-height: 200px; filter: grayscale(100%)"
              :src="logo"
              class="rounded"
            />
          </div>

          <span
            v-if="printOptions.printTitle"
            class="font-weight-bold text-25 text-center"
          >
            {{ party.name }}
          </span>
          <span v-if="printOptions.printSubTitle" class="pt-3">Subtítulo</span>
          <span
            v-if="printOptions.printDateTime"
            class="font-weight-bold text-center"
          >
            {{ party.date | date("DD [de] MMMM [de] YYYY [às] HH[h]mm") }}
          </span>
          <span
            v-if="printOptions.printLocation"
            class="font-weight-bold text-center"
          >
            {{ party.Address?.street }}, {{ party.Address?.number }} -
            {{ party.Address?.city }}, {{ party.Address?.state }}
          </span>

          <qrcode-vue
            v-if="printOptions.printQrCode"
            class="d-flex justify-center ma-1 pa-5 mb-2 rounded"
            :value="origin"
            background="transparent"
            size="150"
            level="M"
            :margin="5"
            ref="qrCode"
            render-as="svg"
          />
          <div
            v-if="printOptions.printSector || printOptions.printLot"
            style="background-color: black; height: 35px"
            class="w-full align-center text-17 d-flex justify-center text-center font-weight-bold"
          >
            <span
              v-if="printOptions.printSector"
              class="font-weight-bold"
              style="color: white !important"
            >
              Front Stage - Front
            </span>
            <span
              v-if="printOptions.printLot"
              class="font-weight-bold"
              style="color: white !important"
            >
              - 1 Lote
            </span>
          </div>
          <span
            v-if="printOptions.printPrice"
            class="mb-0 pt-2 font-weight-bold"
          >
            Valor: 100,00
          </span>
          <span
            v-if="printOptions.printTotalAmount"
            class="pt-2 font-weight-bold"
          >
            Valor Total: 112,00
          </span>
          <p v-if="printOptions.printFee" class="mb-0 font-weight-bold">
            Taxa: 12,00
          </p>
          <div>
            <p
              v-if="printOptions.printOwnerName"
              class="mb-0 mt-3 font-weight-bold"
            >
              Pedro Henrique
            </p>
            <p
              v-if="printOptions.printOwnerDocument"
              class="mb-0 font-weight-bold"
            >
              123.456.789-00
            </p>
          </div>
          <v-card
            style="
              background-color: #fff1d6;
              border: 1px solid rgb(224, 212, 188);
            "
            class="w-full text-center mt-3 rounded"
            elevation="0"
            outlined
          >
            <v-row dense class="mx-0">
              <v-col class="col-6">
                <div class="mb-3">
                  <p class="mb-0 font-weight-bold">INGRESSO:</p>
                  <p class="mb-0">#4567ABCD</p>
                </div>
                <div class="mb-3">
                  <p class="mb-0 font-weight-bold">COMPRA:</p>
                  <p class="mb-0">
                    {{ new Date() | date("DD/MM/YYYY HH[h]mm") }}
                  </p>
                </div>
                <div>
                  <p class="mb-0 font-weight-bold">FORMA PAGAMENTO:</p>
                  <p class="mb-0">Dinheiro</p>
                </div>
              </v-col>
              <v-col class="col-6">
                <div class="mb-3">
                  <p class="mb-0 font-weight-bold">PAGAMENTO:</p>
                  <p class="mb-0">#5678ABCD</p>
                </div>
                <div class="mb-3">
                  <p class="mb-0 font-weight-bold">IMPRESSÃO:</p>
                  <p class="mb-0">
                    {{ new Date() | date("DD/MM/YYYY HH[h]mm") }}
                  </p>
                </div>
                <div>
                  <p class="mb-0 font-weight-bold">PDV:</p>
                  <p class="mb-0">Ótica Exemplo</p>
                </div>
              </v-col>
            </v-row>
          </v-card>
          <small
            class="mt-2 mb-1 d-block text-center"
            style="max-width: calc(100% - 12px)"
          >
            {{ printOptions.printFooterMessage }}
          </small>
          <span class="font-weight-bold text-17">Organização do evento:</span>
          <span class="mb-0 font-weight-bold text-15">{{
            selectedOrganization.name
          }}</span>
          <span class="mb-2 py-2">IMPRESSÃO: 1/1</span>
        </div>
      </v-card>
      <ticket-dots bottom color="#fff" :size="15" :dots="18" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import QrcodeVue from "qrcode.vue";
import TicketDots from "../../components/global/TicketDots.vue";
export default {
  components: { QrcodeVue, TicketDots },
  props: {
    printOptions: {
      type: Object,
      required: true,
    },
    logo: {
      type: String,
      required: false,
    },
    party: {
      type: Object,
      required: false,
    },
  },
  data: () => ({}),
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    origin() {
      return window.location.origin;
    },
    ImageLink() {
      if (logoOption === 0) return "/img/logo-light.png";
      if (logoOption === 1) return logo;
      else return "";
    },
  },
  methods: {},
};
</script>

<style>
.image-container {
  position: relative;
  display: inline-block;
}

.image-container img {
  display: block;
  width: 100%;
}

.image-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff1d6;
  mix-blend-mode: darken;
  pointer-events: none;
}

.print-preview-card {
  color: black !important;
}

.print-preview-card * {
  color: black !important;
}
</style>
